import { SnippetType, WordType } from 'slices/ProjectSlice.types';

export interface CreateResponseType {
  title: string;
  user_id: string;
  description: string;
  annotation_project_id: string;
  status: string;
  created_at: string;
  updated_at: string;
}
export interface TrainingJobDetails {
  title: string;
  training_job_id: string;
  annotation_project_id: string;
  custom_training_params: CustomTrainingParams;
  voice_ids: string[];
  status: string;
  description: string;
  training_job_type: string;
  admin_name: string;
  approval_status: string;
  job_id: string;
  training_status: string;
  user_id: string;
  use_tones: boolean;
  model_version: 0 | 1 | 2;
  training_pipeline_version: 0 | 1;
  created_at: string;
  updated_at: string;
}

export interface CustomTrainingParams {
  enable: boolean;
  base_annotation_project_id: string;
  base_training_job_id: string;
  finetune_on_same_speaker: boolean;
}

export interface VerifyCTCReponse {
  snippets: [
    {
      snippet_id: string;
      transcript: string;
      words: [
        {
          word: string;
          start_time_ctc: number;
          end_time_ctc: number;
          start_time_mfa: number;
          end_time_mfa: number;
          score: number;
        }
      ];
    }
  ];
}

export interface ProjectDetails {
  title: string;
  asr_service: ASR_TYPE;
  user_id: string;
  description: string;
  annotation_project_id: string;
  status: string;
  created_at: string;
  updated_at: string;
  source_video_file: string;
  downsampled_source_video_file: string;
  thumbnail: string;
  source_language_code: string;
  sampling_rate: string;
  model_language_code: string;
  source_subtitle_file: string;
  source_audio_file: string;
  background_audio_file: string;
  source_audio_duration: number;
  task_id?: string;
  target_srt_enabled: boolean;
  target_subtitle_file?: string;
  audio_sample_rate?: string;
  multimodel_project_ids: Array<string>;
  source_mp3_audio_file?: string;
  error_details?: {
    message: string;
    stack_trace: string;
  };
}
export type ASR_TYPE = 'google_asr' | 'aws_asr' | 'whisper_asr' | 'deepgram_asr';

export enum PROJECT_STATUS {
  DRAFT = 'draft',
  ASR_PROCESSING = 'asr_processing',
  ASR_PROCESSED = 'asr_processed',
  SPLIT_MERGE_PROCESSING = 'split_merge_processing',
  SPLIT_MERGE_PROCESSED = 'split_merge_processed',
  EDIT_SNIPPETS_PROCESSING = 'edit_snippets_processing',
  EDIT_SNIPPETS_PROCESSED = 'edit_snippets_processed',
  MFA_PROCESSING = 'mfa_processing',
  MFA_PROCESSED = 'mfa_processed',
  MFA_FAILED = 'mfa_failed',
  COMPLETED = 'completed',
  DELETED = 'deleted',
  ERROR = 'error'
}
export enum LANGUAGE_OPTIONS {
  EN_US = 'en-US',
  EN_IN = 'en-IN',
  HI_IN = 'hi-IN'
}
export interface BoundaryEqualSpaced {
  threshold_percentage: number;
  max_frames_increase_limit: number;
  left_to_right_boundary_percentage: number;
}

export interface UpsamplingModeParams {
  BOUNDARY_INCLUDED_EQ_SPACED: BoundaryEqualSpaced;
  BOOMERANG_EQ_SPACED: {
    boom_percentage: number;
  };
}
export interface DownSamplingModeParams {
  BOUNDARY_INCLUDED_EQ_SPACED: BoundaryEqualSpaced;
}

export type VerifyErrorWordType = {
  index: number;
  invalid_characters: string[];
  invalid_phonemes: string[];
  modified_word: string;
  nukta_error: boolean;
  word: string;
};
export type VerifyWordResponseType = {
  modified_transcript: string;
  snippet_id: string;
  source_transcript: string;
  error_words: VerifyErrorWordType[];
};
export type SnippetVerifyResponseType = {
  success: boolean;
  error_snippets: VerifyWordResponseType[];
};

export type PROJECT_STATUS_TYPES = `${PROJECT_STATUS}`;
export type MODAL_LANG_TYPE = 'en-us|hi';
export type ASR_SERVICES = ASR_TYPE;
export type LANGUAGE_TYPE = `${LANGUAGE_OPTIONS}`;
export interface Project {
  [key: string]: string | boolean | number | null | undefined | [];
}
export type SAMPLING_TYPE = keyof typeof SAMPLING_RATES;
export const SAMPLING_RATES = {
  TWENTY_TWO: '22050',
  FORTY_FOUR: '44100'
};

export const DEFAULT_ZOOM = 2000;
export const WAVEFORM_ZOOM = {
  QUATER: 0.25,
  HALF: 0.5,
  ONE: 1,
  POINT_TWO_FIVE: 1.25,
  ONEPOINTFIVE: 1.5,
  DOUBLE: 2,
  FIVE: 5,
  TEN: 10
};
export enum video_upsampling_mode {
  boomerang = 'BOOMERANG',
  equally_space = 'EQUALLY_SPACE',
  interpolation = 'INTERPOLATION',
  boomerang_eq_space = 'BOOMERANG_EQ_SPACED',
  boundary_included_eq_spaced = 'BOUNDARY_INCLUDED_EQ_SPACED'
}
export enum video_downsampling_mode {
  interpolation = 'INTERPOLATION',
  dpbrute = 'DPBRUTE',
  greedy = 'GREEDY',
  equally_space = 'EQUALLY_SPACE',
  frame_cut = 'FRAME_CUT',
  keep_same_length = 'KEEP_SAME_LENGTH',
  boundary_included_eq_spaced = 'BOUNDARY_INCLUDED_EQ_SPACED',
  keep_same_length_eq_spaced = 'KEEP_SAME_LENGTH_EQ_SPACED'
}
export enum enable_lipsync {
  true = 'true',
  false = 'false'
}
export interface PitchInterface {
  control: number;
  delta: number;
  min: number;
  max: number;
}

export type AdditionalInfoType = MetadataAttributesInterface;

export const NORM_VALUES = {
  DEFAULT: 'DEFAULT',
  NO_NORM: 'NO_NORM',
  MEAN: 'MEAN',
  MEAN_WITH_ABS: 'MEAN_WTH_ABS',
  Z_NORM: 'Z_NORM'
} as const;

export const BG_AUDIO_UPSAMPLING_VALUES = {
  DEFAULT: 'DEFAULT',
  LOOP: 'LOOP'
} as const;

export const BG_AUDIO_DOWNSAMPLING_VALUES = {
  DEFAULT: 'DEFAULT',
  TRIM: 'TRIM'
} as const;

export interface MetadataAttributesInterface {
  srt_crf_quality: number;
  use_inference_level_bg_audio: boolean;
  bgaudio_upsample_mode: (typeof BG_AUDIO_UPSAMPLING_VALUES)[keyof typeof BG_AUDIO_UPSAMPLING_VALUES];
  bgaudio_downsample_mode: (typeof BG_AUDIO_DOWNSAMPLING_VALUES)[keyof typeof BG_AUDIO_DOWNSAMPLING_VALUES];
  video_upsampling_mode: string;
  video_downsampling_mode: string;
  prebaked_assets: boolean;
  inference_rms: number;
  remove_clicks: boolean;
  energy_norm: (typeof NORM_VALUES)[keyof typeof NORM_VALUES];
  pitch_norm: (typeof NORM_VALUES)[keyof typeof NORM_VALUES];
  lip_sync_params: {
    model_mode: (typeof MODEL_MODES)[keyof typeof MODEL_MODES];
    img_width: number;
    img_height: number;
    enable_gpen: boolean;
    blend_replacement: boolean;
    replacement_mode: (typeof REPLACEMENT_MODES)[keyof typeof REPLACEMENT_MODES];
    frame_crop_mode: (typeof FRAME_CROP_MODES)[keyof typeof FRAME_CROP_MODES];
    use_lip_sync: boolean;
    replacement_params: {
      x_pad: number;
      y_pad: number;
      mask_blur_kernel_size: number;
      mask_erosion_kernel_size: number;
      mask_erosion_kernel_size_y: number;
      color_transfer: boolean;
    };
  };
  video_crf_quality: number;
}

export const FRAME_CROP_MODES = {
  new: 'new',
  old: 'old',
  stylesync_align: 'stylesync_align',
  stylesync_align_eye: 'stylesync_align_eye'
} as const;

export const MODEL_MODES = {
  wav2lip_rgb: 'wav2lip_rgb',
  wav2lip_unet: 'wav2lip_unet',
  stylelipsync_256: 'stylelipsync_256',
  wav2lip_lipformer_vqgan: 'wav2lip_lipformer_vqgan',
  stylelipsync_vit_256: 'stylelipsync_vit_256'
} as const;

export const REPLACEMENT_MODES = {
  lower_half: 'lower_half',
  lip: 'lip',
  frame_mask: 'frame_mask',
  global_mask: 'global_mask'
} as const;

export interface PhonemeAttributesInterface {
  pitch_clamp: PitchInterface;
  energy_clamp: PitchInterface;
  duration_clamp: PitchInterface;
}
export interface MynaSnippetType {
  snippet_id: string;
  transcript: string;
  start_time: number;
  end_time: number;
  replacements?: ReplacementType[];
}
export interface FinalSourceSnippetsType extends MynaSnippetType {
  words: WordType[];
}

export interface ReplacementType extends PhonemeAttributesInterface {
  word_start_index: number;
  word_end_index: number;
  skip_lipsync: boolean;
  skip_srt_overlay: boolean;
  replacement_sp_value: number;
  tag_name: string;
  prefix: string;
  suffix: string;
  up_down_rep_range_sec: [number, number];
  lip_sync_offsets: [number, number];
  upsampling_mode_params: UpsamplingModeParams;
  downsampling_mode_params: DownSamplingModeParams;
  replacement_rms: number;
  default_project_id?: string;
  target_snippet: number;
  target_start_index: number;
  target_end_index: number;
  video_upsampling_mode: string;
  video_downsampling_mode: string;
  energy_norm: (typeof NORM_VALUES)[keyof typeof NORM_VALUES];
  pitch_norm: (typeof NORM_VALUES)[keyof typeof NORM_VALUES];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  xi_labs_params: any;
  constant_time_output: boolean;
}
export interface MynaProjectType {
  title: string;
  training_job_id: string;
  unique_id?: string;
  annotation_project_id?: string;
  myna_project_id?: string;
  voice_id?: string;
  user_id?: string;
  snippets: MynaSnippetType[];
  tags: string[];
  model_language?: string;
  additional_info: AdditionalInfoType;
  non_ai_tags: {
    in_video_tags: {
      [key: string]: {
        tag_name: string;
        in_video_tag_type: string;
        fallback_tag_value: string;
      };
    };
    live_url_tags: {
      [key: string]: {
        tag_name: string;
        fallback_url: string;
      };
    };
    text_tags: {
      [key: string]: {
        tag_name: string;
        fallback_text: string;
      };
    };
  };
  target_srt_paths: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  srt_params: Record<string, any>;
  multimodel_project_ids: Array<string>;
  campaign_ui_name: string;
  created_at: string;
  updated_at: string;
}

export type SuccessType = {
  success: boolean;
  request_id?: string;
};

export type TextGridType = Array<SnippetType>;
