import * as Sentry from '@sentry/browser';
import { AmplitudeEventProperties } from 'types/custom';

import { User } from '../types/User';

export const initAmplitude = (user: User): void => {
  // const amplitudeApiKey = process.env.NEXT_APP_AMPLITUDE_API_KEY;
  // if (amplitudeApiKey) amplitude.getInstance().init(amplitudeApiKey);

  // If User Id is available, set the amplitude user id
  if (user) {
    Sentry.setUser({
      'User ID': user.id,
      Name: user.name,
      Email: user.email,
      'First Name': user.firstName,
      'Last Name': user.lastName,
      Active: user.active
    });
    // setAmplitudeUserProperties(user);
  }
};

export const sendAmplitudeData = (
  eventName: string,
  eventProperties: AmplitudeEventProperties
): void => {
  Sentry.captureMessage(JSON.stringify(eventProperties, null, 4), {
    tags: {
      name: eventName
    }
  });
  // amplitude.getInstance().logEvent(eventName, eventProperties);
};
