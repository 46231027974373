import { ProjectSnippetsType, SnippetType } from 'slices/ProjectSlice.types';
import {
  ASR_TYPE,
  CreateResponseType,
  CustomTrainingParams,
  FinalSourceSnippetsType,
  LANGUAGE_TYPE,
  MODAL_LANG_TYPE,
  MynaProjectType,
  PROJECT_STATUS,
  PROJECT_STATUS_TYPES,
  Project,
  ProjectDetails,
  SAMPLING_RATES,
  SAMPLING_TYPE,
  SnippetVerifyResponseType,
  SuccessType,
  TrainingJobDetails,
  VerifyCTCReponse
} from 'types/Project';

import {
  AUDIO_SYNC,
  EDIT_TEXT_SNIPPETS,
  EDIT_TITLE,
  GET_DOWNSAMPLING_MODES,
  GET_UPSAMPLING_MODES,
  MFA_SNIPPETS,
  MYNA_PATH,
  PROJECTS_PATH,
  SOURCE_SNIPPETS,
  SPLIT_MERGE_SNIPPETS,
  STATUS,
  TRAININGJOB_PATH,
  UPDATE,
  VERIFY_CTC,
  VERIFY_SNIPPETS
} from 'constants/endpoints';

import client from './apiClient';

const createNewProject = async (
  title: string,
  description: string,
  userId: string
): Promise<CreateResponseType> => {
  const URL = `${PROJECTS_PATH}`;
  const { data } = await client.post<CreateResponseType>(URL, {
    title,
    user_id: userId,
    description
  });
  return data;
};
const createNewTraining = async (
  title: string,
  description: string,
  user_id: string,
  annotation_project_id: string,
  training_job_type: string,
  tonalEmbeds: boolean,
  custom_training_params: CustomTrainingParams,
  model_version: number,
  training_pipeline_version: number,
  voice_ids?: string[]
): Promise<TrainingJobDetails> => {
  const URL = `${TRAININGJOB_PATH}?training_job_type=${training_job_type}`;
  const { data } = await client.post<TrainingJobDetails>(URL, {
    title,
    user_id,
    voice_ids,
    description,
    annotation_project_id,
    custom_training_params,
    model_version,
    training_pipeline_version,
    use_tones: tonalEmbeds
  });
  return data;
};
const getProjectById = async (projectId: string): Promise<ProjectDetails> => {
  const URL = `${PROJECTS_PATH}/${projectId}`;
  const { data } = await client.get<ProjectDetails>(URL);
  return data;
};
export const getAnnotationProjectDetails = async (
  projectId: string
): Promise<Record<string, string>> => {
  const URL = `${PROJECTS_PATH}/${projectId}/myna_projects`;
  const { data } = await client.get<Record<string, string>>(URL);
  return data;
};
export const getMynaBackendDetails = async (projectId: string): Promise<Record<string, string>> => {
  const URL = `${TRAININGJOB_PATH}/${projectId}/myna_projects`;
  const { data } = await client.get<Record<string, string>>(URL);
  return data;
};
const getProjectFinalSnippets = async (
  annotation_project_id: string
): Promise<{ snippets: FinalSourceSnippetsType[]; target_srt_enabled?: boolean }> => {
  const URL = `${PROJECTS_PATH}/${annotation_project_id}/final_source_snippets`;
  const { data } = await client.get<{ snippets: FinalSourceSnippetsType[] }>(URL);
  if (data) return data;
  return null;
};

export const getTargetSRT = async (
  annotation_project_id: string
): Promise<FinalSourceSnippetsType[]> => {
  const URL = `${MYNA_PATH}/${annotation_project_id}/target_snippets?lang_in=en-US`;
  const { data } = await client.get<{ snippets: FinalSourceSnippetsType[] }>(URL);
  if (data.snippets) return data.snippets;
  return null;
};

const getProjects = async (): Promise<Array<Project | ProjectDetails>> => {
  const { data } = await client.get<Array<Project | ProjectDetails>>(PROJECTS_PATH, {
    params: {
      skip: 0
    }
  });
  return data;
};

const getTrainingJobs = async (): Promise<Array<TrainingJobDetails>> => {
  const { data } = await client.get<Array<TrainingJobDetails>>(TRAININGJOB_PATH, {
    params: {
      skip: 0
    }
  });
  return data;
};
const getTrainingJob = async (training_id: string): Promise<TrainingJobDetails> => {
  const { data } = await client.get<TrainingJobDetails>(
    `${TRAININGJOB_PATH}/${training_id}?training_job_id=${training_id}`
  );
  return data;
};
const requestApproval = async (training_id: string) => {
  const { data } = await client.post<TrainingJobDetails>(
    `${TRAININGJOB_PATH}/${training_id}/request_training_approval`
  );
  return data;
};
const fetchAudioSyncStatus = async (training_id: string): Promise<TrainingJobDetails> => {
  const { data } = await client.get<TrainingJobDetails>(
    `${TRAININGJOB_PATH}/${training_id}/training_status${AUDIO_SYNC}`
  );
  return data;
};
const cancelAudioSync = async (training_id: string) => {
  const { data } = await client.post<TrainingJobDetails>(
    `${TRAININGJOB_PATH}/${training_id}/cancel${AUDIO_SYNC}`
  );
  return data;
};
const downloadSrt = async (projectId: string, snippets: SnippetType[]) => {
  const { data } = await client.post<string>(`${PROJECTS_PATH}/${projectId}/download_srt`, {
    snippets
  });
  return data;
};
const updateProjectTitle = async (title: string, projectId: string): Promise<ProjectDetails> => {
  const URL = `${PROJECTS_PATH}/${projectId}/${EDIT_TITLE}`;
  const { data } = await client.put<ProjectDetails>(URL, { title });
  return data;
};
const updateTrainingDetails = async (
  title: string,
  annotation_project_id: string,
  description: string,
  training_job_id: string,
  tonalEmbeds: boolean,
  custom_training_params: CustomTrainingParams,
  model_version: number,
  pipelineVersion: number,
  voice_ids?: string[]
): Promise<TrainingJobDetails> => {
  const URL = `${TRAININGJOB_PATH}/${training_job_id}/update?training_job_id=${training_job_id}`;
  const { data } = await client.put<TrainingJobDetails>(URL, {
    title,
    annotation_project_id,
    description,
    training_job_id,
    use_tones: tonalEmbeds,
    custom_training_params,
    model_version,
    training_pipeline_version: pipelineVersion,
    voice_ids
  });
  return data;
};
const uploadProjectDetails = async (
  video_file: File,
  modalLang: MODAL_LANG_TYPE,
  srcLang: LANGUAGE_TYPE,
  sampling_rate: SAMPLING_TYPE,
  project_id: string,
  asrService?: ASR_TYPE,
  srtFile?: File,
  audioFile?: File
) => {
  const uploadFormData = new FormData();
  uploadFormData.append('video_file', video_file);
  if (audioFile) uploadFormData.append('audio_file', audioFile);
  srtFile && uploadFormData.append('srt_file', srtFile);
  let URL = `${PROJECTS_PATH}/${project_id}/${UPDATE}?source_language_code=${srcLang}&model_language_code=${modalLang}&audio_sample_rate=${SAMPLING_RATES[sampling_rate]}`;
  if (asrService) {
    URL = `${PROJECTS_PATH}/${project_id}/${UPDATE}?source_language_code=${srcLang}&model_language_code=${modalLang}&asr_service=${asrService}&audio_sample_rate=${SAMPLING_RATES[sampling_rate]}`;
  }
  const { data } = await client.put(URL, uploadFormData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return data;
};
const uploadProjectDetailsv2 = async (
  modalLang: MODAL_LANG_TYPE,
  srcLang: LANGUAGE_TYPE,
  sampling_rate: SAMPLING_TYPE,
  project_id: string,
  responses: Array<boolean>,
  asrService?: ASR_TYPE
) => {
  const uploadFormData = {
    video_file: responses[0],
    audio_file: responses[1],
    srt_file: responses[2],
    source_language_code: srcLang,
    model_language_code: modalLang,
    audio_sample_rate: SAMPLING_RATES[sampling_rate]
  };
  const URL = `${PROJECTS_PATH}/${project_id}/${UPDATE}`;
  if (asrService) {
    uploadFormData['asr_service'] = asrService;
  }
  const { data } = await client.put(URL, uploadFormData);
  return data;
};
const deleteProject = async (projectId: string): Promise<SuccessType> => {
  const URL = `${PROJECTS_PATH}/${projectId}`;
  const { data } = await client.delete<SuccessType>(URL, {});
  return data;
};
const duplicateProject = async (projectId: string): Promise<ProjectDetails> => {
  const URL = `${PROJECTS_PATH}/${projectId}/duplicate_project`;
  const { data } = await client.post<ProjectDetails>(URL);
  return data;
};

const fetcheSnippets = async (
  projectId: string,
  status: PROJECT_STATUS_TYPES,
  payload?: string
) => {
  let URL = '';
  if (status === PROJECT_STATUS.DRAFT) {
    URL = `${PROJECTS_PATH}/${projectId}/${SOURCE_SNIPPETS}`;
    if (payload) {
      URL = `${PROJECTS_PATH}/${projectId}/${SOURCE_SNIPPETS}?asr_service=${payload}`;
    }
  } else if (
    status === PROJECT_STATUS.SPLIT_MERGE_PROCESSING ||
    status === PROJECT_STATUS.ASR_PROCESSED
  ) {
    URL = `${PROJECTS_PATH}/${projectId}/${SPLIT_MERGE_SNIPPETS}`;
  } else if (
    status === PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING ||
    status === PROJECT_STATUS.SPLIT_MERGE_PROCESSED
  ) {
    URL = `${PROJECTS_PATH}/${projectId}/${EDIT_TEXT_SNIPPETS}`;
  } else if (
    status === PROJECT_STATUS.EDIT_SNIPPETS_PROCESSED ||
    status === PROJECT_STATUS.MFA_PROCESSING ||
    status === PROJECT_STATUS.MFA_PROCESSED
  ) {
    URL = `${PROJECTS_PATH}/${projectId}/${MFA_SNIPPETS}?version=v2`;
  }

  const { data } = await client.get<ProjectSnippetsType>(URL);
  return data;
};
const updateSplitMergeSnippets = async (
  projectId: string,
  payload: SnippetType[],
  status: PROJECT_STATUS.SPLIT_MERGE_PROCESSED | PROJECT_STATUS.SPLIT_MERGE_PROCESSING
) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${SPLIT_MERGE_SNIPPETS}`;
  const response = await client.post<ProjectSnippetsType>(URL, {
    snippets: payload,
    status
  });

  return response;
};
export const setTargetSnippets = async (projectId: string, payload: SnippetType[]) => {
  const URL = `${MYNA_PATH}/${projectId}/target_snippets?lang_in=en-US`;
  const response = await client.post<ProjectSnippetsType>(URL, {
    snippets: payload,
    status: 'draft'
  });

  return response;
};
const verifyCTCTimeStamps = async (projectId: string, payload: SnippetType[]) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${VERIFY_CTC}`;
  const { data } = await client.post<VerifyCTCReponse>(URL, {
    snippets: payload
  });
  return data;
};

const verifyEditSnippets = async (projectId: string, payload: SnippetType[]) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${VERIFY_SNIPPETS}`;
  const { data } = await client.post<SnippetVerifyResponseType>(URL, {
    snippets: payload
  });
  return data;
};
const updateEditSnippets = async (
  projectId: string,
  payload: SnippetType[],
  status: PROJECT_STATUS.EDIT_SNIPPETS_PROCESSED | PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING
) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${EDIT_TEXT_SNIPPETS}`;
  const response = await client.post<ProjectSnippetsType>(URL, {
    snippets: payload,
    status
  });
  return response;
};
const updatePhonemeSnippets = async (
  projectId: string,
  payload: SnippetType[],
  status: PROJECT_STATUS.MFA_PROCESSED | PROJECT_STATUS.MFA_PROCESSING
) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${MFA_SNIPPETS}`;
  const { data } = await client.post<ProjectSnippetsType>(URL, {
    snippets: payload,
    status
  });
  return data;
};
const updateStatus = async (projectId: string, status: PROJECT_STATUS_TYPES) => {
  const URL = `${PROJECTS_PATH}/${projectId}/${STATUS}?status_in=${status}`;
  const { data } = await client.put<ProjectDetails>(URL);
  return data;
};

const getMynaProjects = async (): Promise<Array<MynaProjectType[]>> => {
  const { data } = await client.get<Array<MynaProjectType[]>>(MYNA_PATH);
  return data;
};
const getMynaProject = async (unique_id: string): Promise<Array<MynaProjectType>> => {
  const { data } = await client.get<Array<MynaProjectType>>(`${MYNA_PATH}/${unique_id}`);
  return data;
};
const fetchSyncMynaProject = async (unique_id: string): Promise<MynaProjectType> => {
  const { data } = await client.post<MynaProjectType>(`${MYNA_PATH}/${unique_id}/sync`);
  return data;
};

const newMynaproject = async (payload: MynaProjectType): Promise<MynaProjectType> => {
  const { data } = await client.post<MynaProjectType>(MYNA_PATH, payload);
  return data;
};

const updateMyna_Project = async (
  unique_id: string,
  payload: MynaProjectType
): Promise<MynaProjectType> => {
  const { data } = await client.put<MynaProjectType>(`${MYNA_PATH}/${unique_id}/update`, payload);
  return data;
};
const deleteMynaProject = async (unique_id: string) => {
  const URL = `${MYNA_PATH}/${unique_id}`;
  const { data } = await client.delete<SuccessType>(URL);
  return data;
};
const uploadVideo = async (payload: File, project_id: string): Promise<Project> => {
  const uploadFormData = new FormData();
  uploadFormData.append('file', payload);
  const URL = `${PROJECTS_PATH}/${project_id}/upload_video`;
  const { data } = await client.post(URL, uploadFormData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return data;
};
export const uploadVideov2 = async (project_id: string): Promise<Project> => {
  const URL = `${PROJECTS_PATH}/${project_id}/upload_video`;
  const { data } = await client.post(URL);
  return data;
};
export const uploadTargetSRT = async (
  payload: File,
  language: string,
  project_id: string
): Promise<Project> => {
  const uploadFormData = new FormData();
  uploadFormData.append('file', payload);
  const URL = `${MYNA_PATH}/${project_id}/upload_target_srt`;
  const { data } = await client.post(URL, uploadFormData, {
    headers: { 'content-type': 'multipart/form-data' },
    params: {
      lang_in: language
    }
  });
  return data;
};
const prebakeApproval = async (
  unique_id: string,
  // generate_prebake: boolean,
  delete_prebake_assets: boolean,
  delete_srt_assets: boolean
): Promise<SuccessType> => {
  const { data } = await client.post<SuccessType>(
    `${MYNA_PATH}/${unique_id}/request_prebake_approval?delete_prebake_assets=${delete_prebake_assets}&delete_srt_assets=${delete_srt_assets}`
  );
  return data;
};
const getGTTSPlay = async (annotation_project_id: string, text: string): Promise<string> => {
  const { data } = await client.post<string>(
    `${PROJECTS_PATH}/${annotation_project_id}/gtts_play`,
    {
      text
    },
    { responseType: 'arraybuffer' }
  );
  return data;
};
const transferProject = async (
  unique_id: string,
  target_project_id: string,
  transferMM: boolean,
  multiModelMapping: Record<string, Record<string, string>>,
  transferFlags: Record<string, boolean>
): Promise<string> => {
  const { data } = await client.post<string>(
    `${MYNA_PATH}/${unique_id}/transfer?target_project_id=${target_project_id}`,
    {
      transfer_multimodel_projects: transferMM,
      multimodel_project_mappings: multiModelMapping,
      transfer_flags: transferFlags
    }
  );
  return data;
};
const duplicatemynaProject = async (
  unique_id: string,
  assignTo: string,
  projectTitle: string,
  transferMM: boolean,
  multiModelMapping: Record<string, Record<string, string>>
): Promise<SuccessType> => {
  const { data } = await client.post<SuccessType>(
    `${MYNA_PATH}/${unique_id}/duplicate?assign_to=${assignTo}&project_title=${projectTitle}`,
    {
      transfer_multimodel_projects: true,
      multimodel_project_mappings: multiModelMapping
    }
  );
  return data;
};
const getTransferedProjects = async (unique_id: string): Promise<Array<string>> => {
  const { data } = await client.get<Array<string>>(`${MYNA_PATH}/${unique_id}/tranferred_projects`);
  return data;
};

const getAssociatedMynaProjects = async (projectId: string) => {
  const { data } = await client.get(`${PROJECTS_PATH}/${projectId}/myna_projects`);
  return data;
};

export const getDownsamplingModes = async (): Promise<Array<string>> => {
  const { data } = await client.get<Array<string>>(GET_DOWNSAMPLING_MODES);
  return data;
};

export const getUpsamplingModes = async (): Promise<Array<string>> => {
  const { data } = await client.get<Array<string>>(GET_UPSAMPLING_MODES);
  return data;
};

export {
  cancelAudioSync,
  createNewProject,
  createNewTraining,
  deleteMynaProject,
  deleteProject,
  downloadSrt,
  duplicateProject,
  duplicatemynaProject,
  fetchAudioSyncStatus,
  fetchSyncMynaProject,
  fetcheSnippets,
  getAssociatedMynaProjects,
  getGTTSPlay,
  getMynaProject,
  getMynaProjects,
  getProjectById,
  getProjectFinalSnippets,
  getProjects,
  getTrainingJob,
  getTrainingJobs,
  getTransferedProjects,
  newMynaproject,
  prebakeApproval,
  requestApproval,
  transferProject,
  updateEditSnippets,
  updateMyna_Project,
  updatePhonemeSnippets,
  updateProjectTitle,
  updateSplitMergeSnippets,
  updateStatus,
  updateTrainingDetails,
  uploadProjectDetails,
  uploadProjectDetailsv2,
  uploadVideo,
  verifyCTCTimeStamps,
  verifyEditSnippets
};
