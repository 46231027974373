import { createSlice } from '@reduxjs/toolkit';
import _filter from 'lodash/filter';
import { MynaProjectType } from 'types/Project';

const initialState: { mynaprojects: MynaProjectType[] } = {
  mynaprojects: []
};

export const MynaprojectSlice = createSlice({
  name: 'mynaproject',
  initialState,
  reducers: {
    updateMynaProject: (state, action) => {
      state.mynaprojects = [
        action.payload,
        ..._filter(
          state.mynaprojects,
          (project) => project.annotation_project_id !== action.payload.annotation_project_id
        )
      ];
    },
    updateSyncMynaProject: (state, action) => {
      state.mynaprojects = [
        action.payload,
        ..._filter(state.mynaprojects, (project) => project.unique_id !== action.payload.unique_id)
      ];
    },
    deleteMyna_Project: (state, action) => {
      state.mynaprojects = [
        ..._filter(state.mynaprojects, (project) => project.unique_id !== action.payload)
      ];
    }
  }
});

export const { updateSyncMynaProject, updateMynaProject, deleteMyna_Project } =
  MynaprojectSlice.actions;

export default MynaprojectSlice.reducer;
