import { createSlice } from '@reduxjs/toolkit';
import { ScreenFlowTypes } from 'components/Newproject/newproject.types';

import { projectState } from './ProjectSlice.types';

const initialState: projectState = {
  projectModal: {
    showProjectModal: false,
    currentScreen: ScreenFlowTypes.DETAILS,
    currentProject: ''
  },
  downsamplingModes: null,
  upsamplingModes: null,
  showAnnotationModal: false,
  annotationDetails: null,
  mynaBackendDetails: null
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateAnnotationModal: (state, action) => {
      state.showAnnotationModal = action.payload;
    },
    updateAnnotationProject: (state, action) => {
      state.annotationDetails = action.payload;
    },
    updateMynaBackendDetails: (state, action) => {
      state.mynaBackendDetails = action.payload;
    },
    setProjectModal: (state, action) => {
      state.projectModal = { ...state.projectModal, ...action.payload };
    },
    setDownsamplingModes: (state, action) => {
      state.downsamplingModes = action.payload;
    },
    setUpsamplingModes: (state, action) => {
      state.upsamplingModes = action.payload;
    }
  }
});

export const {
  setProjectModal,
  setDownsamplingModes,
  setUpsamplingModes,
  updateAnnotationProject,
  updateAnnotationModal,
  updateMynaBackendDetails
} = projectSlice.actions;

export default projectSlice.reducer;
