import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import client from 'services/apiClient';

import { QueryMeta } from './types/baseQueryTypes';

// const client = axios.create({
//   baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
//   timeout: 120000,
//   retry: false,
//   onRetry: _noop
// } as IAxiosRequestConfigRetry);

// // Request interceptor

// const addHeaderRequestInterceptor = (request) => {
//   let token = getAccessToken();
//   if (isSubUser()) {
//     token = getSubUserToken();
//   }

//   if (token) {
//     request.headers.Authorization = `Bearer ${token}`;
//   }
//   return request;
// };

// const errorInterceptor = (error) => Promise.reject(error);

// const responseInterceptor = (response) => response;

// const reauthenticationInterceptor = (error) => {
//   captureException(error);
//   const originalRequest = error.config as IAxiosRequestConfigRetry;
//   // If UnAuthorized error, reject and redirect to login
//   if (
//     error.response?.status === HttpResponse.Unauthorised &&
//     originalRequest.url === '/auth/token'
//   ) {
//     logoutUser();
//     return Promise.reject(error);
//   }
//   if (error.response?.status === HttpResponse.ValidationError) {
//     // logoutUser();
//     return Promise.reject(error);
//   }
//   if (
//     isSubUser() &&
//     error.response?.status === HttpResponse.Unauthorised &&
//     !originalRequest.retry
//   ) {
//     // TODO - Remove alert and replace with Toast
//     alert(`${getUserInfo().email} Session Expired `);
//     clearSubuserToken();
//     window.location.reload();
//   }

//   if (error.response?.status === HttpResponse.Unauthorised && !originalRequest.retry) {
//     originalRequest.retry = true;
//     originalRequest.onRetry();
//     const newAxiosClient = axios.create({
//       baseURL: process.env.REACT_APP_API_ENDPOINT
//     } as RetryConfig);
//     return newAxiosClient
//       .post<Token>(
//         `${process.env.REACT_APP_API_ENDPOINT}/${REFRESH_TOKEN_PATH}`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${getRefreshToken()}`
//           }
//         }
//       )
//       .then((res) => {
//         if (res.status === HttpResponse.Created || res.status === HttpResponse.Ok) {
//           const { access_token, refresh_token = '' } = res.data;
//           setTokens({
//             accessToken: access_token,
//             refreshToken: refresh_token
//           });
//           client.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
//           return client(originalRequest);
//         }
//         if (res.status === HttpResponse.Unauthorised) {
//           logoutUser();
//         }
//         return Promise.reject(error);
//       })
//       .catch((err) => {
//         logoutUser();
//         return Promise.reject(err);
//       });
//   }
//   return Promise.reject(error);
// };
// // Request interceptor
// client.interceptors.request.use(addHeaderRequestInterceptor, errorInterceptor);

// // Response interceptor
// client.interceptors.response.use(responseInterceptor, reauthenticationInterceptor);

// const logoutUser = () => {
//   clearTokens();
// };

// export interface IAxiosRequestConfigRetry extends AxiosRequestConfig {
//   retry?: boolean;
//   onRetry?: () => void;
// }

export const axiosBaseQuery =
  <TResponse, TError>(
    { baseUrl }: { baseUrl: string } = { baseUrl: process.env.REACT_APP_API_ENDPOINT }
  ): BaseQueryFn<AxiosRequestConfig, TResponse, TError, unknown, QueryMeta> =>
  async ({ url, ...axiosConfig }, api) => {
    try {
      const result = (await client({
        url: (axiosConfig?.baseURL ?? baseUrl) + url,
        ...axiosConfig
      })) as AxiosResponse<TResponse>;
      return {
        data: result.data,
        meta: { api }
      } as QueryReturnValue<TResponse, undefined, QueryMeta>;
    } catch (error) {
      const axiosError = error as AxiosError<TError>;
      return {
        error: axiosError,
        meta: {
          api
        }
      } as QueryReturnValue<undefined, TError, QueryMeta>;
    }
  };
