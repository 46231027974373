import { AxiosError } from 'axios';
import { UseMynaReturn } from 'hooks/useMyna';
import { update } from 'lodash';
import CustomerAuth from 'services/auth';
import { RootState } from 'store/AppStore';
import { MynaProjectType, ProjectDetails, TrainingJobDetails } from 'types/Project';
import { User } from 'types/User';
import { initAmplitude } from 'utils/amplitude';

import {
  GET_USER_DETAILS,
  MULTIMODEL_PROJECTS,
  MYNA_PATH,
  PROJECTS_PATH,
  TRAININGJOB_PATH
} from 'constants/endpoints';

import { apiSlice } from '../../apiSlice';
import { QueryMeta } from '../../types/baseQueryTypes';
import { errorResponseHandler } from '../../utils/errorTransformer';

import { GET_API_TAGS } from './getApisTags';

interface FileUploadLinkRequest {
  projectId: string;
  file_type:
    | 'source_video'
    | 'source_audio'
    | 'source_srt'
    | 'update_video'
    | 'transfer_csv'
    | 'aae_assets_tar_file'
    | 'aae_assets_master_json';
}

export interface FileUploadLinkResponse {
  s3_put_url: string;
  s3_key?: string;
}

interface PaginationInterface {
  skip?: number;
  limit?: number;
  title?: string;
  start_time?: string;
  end_time?: string;
}

type PaginationResponse<T> = { data: Array<T> } & { size: number };

const getApiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [GET_API_TAGS.AnnotationProject, GET_API_TAGS.MynaProject, GET_API_TAGS.TrainingJob]
});

export const getApisSlice = getApiSliceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getAnnotationProjects_v2: build.query<
        PaginationResponse<ProjectDetails>,
        PaginationInterface
      >({
        query: ({ skip = 0, limit = 10, title = '', start_time = null, end_time = null }) => ({
          url: `${PROJECTS_PATH}`,
          method: 'GET',
          params: {
            skip,
            limit,
            title,
            filter_by: 'updated_at',
            start_time,
            end_time
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching projects');
        },
        providesTags: ({ data }) =>
          data
            ? data.map((project) => ({
                type: GET_API_TAGS.AnnotationProject,
                id: project.annotation_project_id
              }))
            : [],
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getCompletedAnnotationProjects_v2: build.query<
        PaginationResponse<ProjectDetails>,
        PaginationInterface
      >({
        query: ({ skip = 0, limit = 10, title = '' }) => ({
          url: `${PROJECTS_PATH}/completed_projects`,
          method: 'GET',
          params: {
            skip,
            limit,
            title
          }
        }),
        providesTags: ({ data }) =>
          data
            ? data.map((project) => ({
                type: GET_API_TAGS.AnnotationProject,
                id: project.annotation_project_id
              }))
            : [],
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(
            errResponse,
            meta,
            'Error in fetching completed annotation projects'
          );
        },
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getAnnotationProjectDetails: build.query<ProjectDetails, string>({
        query: (project_id) => ({
          url: `${PROJECTS_PATH}/${project_id}`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(
            errResponse,
            meta,
            'Error in fetching annonation project details'
          );
        },
        providesTags: (project) =>
          project?.annotation_project_id
            ? [
                {
                  type: GET_API_TAGS.AnnotationProject,
                  id: project.annotation_project_id
                }
              ]
            : [],
        onQueryStarted: async (projectId, { queryFulfilled, dispatch, getState }) => {
          const response = await queryFulfilled;
          const state = getState() as RootState;
          const entries = getApisSlice.util.selectInvalidatedBy(state, [
            {
              type: GET_API_TAGS.AnnotationProject,
              id: projectId
            }
          ]);

          for (const { endpointName, originalArgs } of entries) {
            if (endpointName !== 'getAnnotationProjects_v2') continue;
            dispatch(
              getApisSlice.util.updateQueryData(
                'getAnnotationProjects_v2',
                originalArgs,
                (draft) => {
                  const projectIndex = draft?.data?.findIndex(
                    (project: ProjectDetails) => project.annotation_project_id === projectId
                  );
                  if (projectIndex !== -1) {
                    update(draft, `data.${projectIndex}`, () => response.data);
                  }
                }
              )
            );
          }
        }
      }),
      getTrainingJobs_v2: build.query<PaginationResponse<TrainingJobDetails>, PaginationInterface>({
        query: ({ skip = 0, limit = 10, title = '' }) => ({
          url: `${TRAININGJOB_PATH}`,
          method: 'GET',
          params: {
            skip,
            limit,
            title
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching training jobs');
        },
        providesTags: ({ data }) =>
          data
            ? data.map((job) => ({
                type: GET_API_TAGS.TrainingJob,
                id: job.training_job_id
              }))
            : [],
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getAllTrainingJobs: build.query<PaginationResponse<TrainingJobDetails>, PaginationInterface>({
        query: ({ skip = 0, limit = 10, title = '' }) => ({
          url: `${TRAININGJOB_PATH}/all_training_jobs`,
          method: 'GET',
          params: {
            skip,
            limit,
            title
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching training jobs');
        },
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getCompletedTrainingJobs: build.query<Array<TrainingJobDetails>, void>({
        query: () => ({
          url: `${TRAININGJOB_PATH}/completed_training_jobs`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching training jobs');
        },
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getTrainingJobDetails: build.query<TrainingJobDetails, string>({
        query: (id) => ({
          url: `${TRAININGJOB_PATH}/${id}`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching training job details');
        },
        providesTags: (job) =>
          job?.training_job_id
            ? [
                {
                  type: GET_API_TAGS.TrainingJob,
                  id: job.training_job_id
                }
              ]
            : [],
        onQueryStarted: async (id, { queryFulfilled, dispatch, getState }) => {
          const response = await queryFulfilled;
          const state = getState() as RootState;
          const entries = getApisSlice.util.selectInvalidatedBy(state, [
            {
              type: GET_API_TAGS.MynaProject,
              id: id
            }
          ]);

          for (const { endpointName, originalArgs } of entries) {
            if (endpointName !== 'getTrainingJobs_v2') continue;
            dispatch(
              getApisSlice.util.updateQueryData('getTrainingJobs_v2', originalArgs, (draft) => {
                const projectIndex = draft?.data?.findIndex(
                  (project: TrainingJobDetails) => project.training_job_id === id
                );
                if (projectIndex !== -1) {
                  update(draft, `data.${projectIndex}`, () => response.data);
                }
              })
            );
          }
        }
      }),
      getMynaProjects_v2: build.query<PaginationResponse<MynaProjectType>, PaginationInterface>({
        query: ({ skip = 0, limit = 10, title = '' }) => ({
          url: `${MYNA_PATH}`,
          method: 'GET',
          params: {
            skip,
            limit,
            title
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching myna projects');
        },
        providesTags: (result) =>
          result?.data
            ? result.data.map((project) => ({
                type: GET_API_TAGS.MynaProject,
                id: project.unique_id
              }))
            : [],
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getMynaProjectsRecursive: build.query<{ multimodel_project_ids: Array<string> }, string>({
        query: (unique_id) => ({
          url: `${MYNA_PATH}/${unique_id}/${MULTIMODEL_PROJECTS}_recursive`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching myna projects');
        },
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getMynaProjectDetails: build.query<MynaProjectType, string>({
        query: (id) => ({
          url: `${MYNA_PATH}/${id}`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching myna projects');
        },
        providesTags: (result) =>
          result?.unique_id ? [{ type: GET_API_TAGS.MynaProject, id: result.unique_id }] : [],
        onQueryStarted: async (id, { queryFulfilled, dispatch, getState }) => {
          const response = await queryFulfilled;
          const state = getState() as RootState;
          const entries = getApisSlice.util.selectInvalidatedBy(state, [
            {
              type: GET_API_TAGS.MynaProject,
              id: id
            }
          ]);

          for (const { endpointName, originalArgs } of entries) {
            if (endpointName !== 'getMynaProjects_v2') continue;
            dispatch(
              getApisSlice.util.updateQueryData('getMynaProjects_v2', originalArgs, (draft) => {
                const projectIndex = draft?.data?.findIndex(
                  (project: MynaProjectType) => project.unique_id === id
                );
                if (projectIndex !== -1) {
                  update(draft, `data.${projectIndex}`, () => response.data);
                }
              })
            );
          }
        }
      }),
      getLiveMynaProjects: build.query<PaginationResponse<MynaProjectType>, PaginationInterface>({
        query: ({ skip = 0, limit = 10, title = '' }) => ({
          url: `${MYNA_PATH}/live_projects`,
          method: 'GET',
          params: {
            skip,
            limit,
            search: title
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching myna projects');
        },
        providesTags: (result) =>
          result?.data
            ? result.data.map((project) => ({
                type: GET_API_TAGS.MynaProject,
                id: project.unique_id
              }))
            : [],
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getMynaProjectsBulk: build.query<Array<MynaProjectType>, Array<string>>({
        query: (myna_project_ids) => ({
          url: `${MYNA_PATH}/myna_projects_bulk`,
          method: 'GET',
          params: {
            myna_project_ids: myna_project_ids.join(',')
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching myna projects');
        },
        providesTags: (result) =>
          result
            ? result.map((project) => ({ type: GET_API_TAGS.MynaProject, id: project.unique_id }))
            : [],
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          await queryFulfilled;
        }
      }),
      getUploadVideoLinkForAnnotationProject: build.query<
        FileUploadLinkResponse,
        FileUploadLinkRequest
      >({
        query: ({ projectId, file_type }) => ({
          url: `${PROJECTS_PATH}/${projectId}/generate_video_upload_link`,
          method: 'GET',
          params: {
            annotation_project_id: projectId,
            file_type
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in getting upload link!!');
        }
      }),
      getFileUploadLinkForMynaProject: build.query<FileUploadLinkResponse, FileUploadLinkRequest>({
        query: ({ projectId, file_type }) => ({
          url: `${MYNA_PATH}/${projectId}/generate_file_upload_link`,
          method: 'GET',
          params: {
            file_type
          }
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in getting upload link!!');
        }
      }),
      getUserDetail: build.query<User, void>({
        query: () => ({
          url: `/${GET_USER_DETAILS}`,
          method: 'GET'
        }),
        transformErrorResponse(errResponse: AxiosError, meta: QueryMeta) {
          return errorResponseHandler(errResponse, meta, 'Error in fetching user details');
        },
        onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
          const { data } = await queryFulfilled;
          if (data) {
            CustomerAuth.setUserInfo(data);
            initAmplitude(data);
          }
        }
      }),
      transferProjectBulk: build.mutation<
        { csv_link: string },
        [...Parameters<UseMynaReturn['transferMynaProject']>, string]
      >({
        query: (args) => {
          const [uniqueId, , , multiModelMapping, transferFlags, csvPath] = args;
          return {
            url: `${MYNA_PATH}/${uniqueId}/transfer_bulk`,
            method: 'post',
            data: {
              transfer_multimodel_projects: true,
              multimodel_project_mappings: multiModelMapping,
              transfer_flags: transferFlags,
              csv_link: csvPath
            }
          };
        }
      }),
      duplicateProjectBulk: build.mutation<
        { csv_link: string },
        [...Parameters<UseMynaReturn['duplicateMynaProject']>, string]
      >({
        query: (args) => {
          const [uniqueId, , projectTitle, , multiModelMapping, csvPath] = args;
          return {
            url: `${MYNA_PATH}/${uniqueId}/duplicate_bulk?project_title=${projectTitle}`,
            method: 'post',
            data: {
              transfer_multimodel_projects: true,
              multimodel_project_mappings: multiModelMapping,
              csv_link: csvPath
            }
          };
        }
      })
    };
  }
});

export const {
  useGetAllTrainingJobsQuery,
  useGetAnnotationProjectDetailsQuery,
  useGetAnnotationProjects_v2Query,
  useGetCompletedAnnotationProjects_v2Query,
  useGetMynaProjectDetailsQuery,
  useGetMynaProjects_v2Query,
  useGetTrainingJobDetailsQuery,
  useGetTrainingJobs_v2Query,
  useGetCompletedTrainingJobsQuery,
  useGetUploadVideoLinkForAnnotationProjectQuery,
  useGetMynaProjectsRecursiveQuery,
  useGetLiveMynaProjectsQuery,
  useGetMynaProjectsBulkQuery,
  useGetUserDetailQuery,
  useGetFileUploadLinkForMynaProjectQuery,
  useLazyGetAllTrainingJobsQuery,
  useLazyGetAnnotationProjectDetailsQuery,
  useLazyGetAnnotationProjects_v2Query,
  useLazyGetCompletedAnnotationProjects_v2Query,
  useLazyGetMynaProjectDetailsQuery,
  useLazyGetMynaProjects_v2Query,
  useLazyGetTrainingJobDetailsQuery,
  useLazyGetTrainingJobs_v2Query,
  useLazyGetCompletedTrainingJobsQuery,
  useLazyGetUploadVideoLinkForAnnotationProjectQuery,
  useLazyGetMynaProjectsRecursiveQuery,
  useLazyGetLiveMynaProjectsQuery,
  useLazyGetMynaProjectsBulkQuery,
  useLazyGetUserDetailQuery,
  useLazyGetFileUploadLinkForMynaProjectQuery,
  useTransferProjectBulkMutation,
  useDuplicateProjectBulkMutation
} = getApisSlice;
