import { motion } from 'framer-motion';
import prettyBytes from 'pretty-bytes';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { uploadFileToS3 } from 'services/miscService';
import {
  FileUploadLinkResponse,
  useLazyGetFileUploadLinkForMynaProjectQuery,
  useLazyGetUploadVideoLinkForAnnotationProjectQuery
} from 'store/api/slices/getApis/getApisSlice';

import styles from './ProjectUpload.module.scss';

interface CommonProps {
  id: string;
  disabled: boolean;
  accept: string;
  text: string;
  fileType: FileTypes;
  projectId: string;
  setFileInfo?: Dispatch<SetStateAction<FileUploadLinkResponse>>;
}

interface PropsForAnnotationProps extends CommonProps {
  setFilesUploaded: Dispatch<
    SetStateAction<
      Record<'SRT' | 'MEDIA' | 'AUDIO' | 'UPDATE_VIDEO', 'uploading' | 'no_file' | 'uploaded'>
    >
  >;
}

interface PropsForMynaProjects extends CommonProps {
  mynaProject: true;
  setFilesUploaded: Dispatch<
    SetStateAction<
      Record<
        'TRANSFER_CSV' | 'AAE_ASSETS_TAR_FILE' | 'AAE_ASSETS_MASTER_JSON',
        'uploading' | 'no_file' | 'uploaded'
      >
    >
  >;
}

type FileTypes =
  | 'SRT'
  | 'MEDIA'
  | 'AUDIO'
  | 'UPDATE_VIDEO'
  | 'TRANSFER_CSV'
  | 'AAE_ASSETS_TAR_FILE'
  | 'AAE_ASSETS_MASTER_JSON';

enum FileTypeMappingToUploadFlow {
  SRT = 'srt',
  MEDIA = 'video',
  AUDIO = 'audio',
  UPDATE_VIDEO = 'update_video',
  TRANSFER_CSV = 'transfer_csv',
  AAE_ASSETS_TAR_FILE = 'aae_assets_tar_file',
  AAE_ASSETS_MASTER_JSON = 'aae_assets_master_json'
}

function S3FileUploadComponent(props: PropsForAnnotationProps | PropsForMynaProjects) {
  const { id, disabled, accept, text, projectId, setFilesUploaded, fileType } = props;
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(null);
  const [uploadVideoLinkTrigger] = useLazyGetUploadVideoLinkForAnnotationProjectQuery();
  const [fileUploadLinkTrigger] = useLazyGetFileUploadLinkForMynaProjectQuery();

  const s3UploadFlow = async (file: File, key: FileTypes) => {
    if (file) {
      try {
        setUploading(true);
        setFilesUploaded((prev) => ({ ...prev, [key]: 'uploading' }));
        const func =
          key === 'TRANSFER_CSV' ||
          key === 'AAE_ASSETS_MASTER_JSON' ||
          key === 'AAE_ASSETS_TAR_FILE'
            ? fileUploadLinkTrigger
            : uploadVideoLinkTrigger;
        const { data, isError } = await func(
          {
            file_type:
              key !== 'UPDATE_VIDEO' &&
              key !== 'TRANSFER_CSV' &&
              key !== 'AAE_ASSETS_MASTER_JSON' &&
              key !== 'AAE_ASSETS_TAR_FILE'
                ? `source_${FileTypeMappingToUploadFlow[key]}`
                : FileTypeMappingToUploadFlow[key],
            projectId
          },
          false
        );
        if (isError) {
          return false;
        }
        const url = data?.s3_put_url;
        await uploadFileToS3(file, url, key, setUploadProgress);
        return data;
      } catch (error) {
        setUploading(false);
        return false;
      }
    }
    return false;
  };

  function cleanup(type: FileTypes) {
    setFileName(null);
    setFile(null);
    setUploading(false);
    setUploadProgress(0);
    setFilesUploaded((prev) => ({ ...prev, [type]: 'no_file' }));
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>, type: FileTypes) {
    if (e.target.files && e.target.files.length > 0) {
      cleanup(type);
      s3UploadFlow(e.target.files[0], type)
        .then((res) => {
          if (res !== false) {
            setFileName(e.target.files[0]?.name);
            setFile(e.target.files[0]);
            setFilesUploaded((prev) => ({ ...prev, [type]: 'uploaded' }));
            props?.setFileInfo?.(res);
          }
        })
        .finally(() => {
          setUploading(false);
          e.target.value = null;
        });
    }
  }

  const disableUpload = file && file.size > 500 * 1024 * 1024;

  return (
    <div className="flex  flex-col items-center justify-center">
      {fileName && (
        <>
          <span className="mb-6 flex h-[20px]  justify-start overflow-hidden overflow-ellipsis text-center text-white">
            File Name: {fileName}
          </span>
          <span className="mb-6 flex h-[20px]  justify-start overflow-hidden overflow-ellipsis text-center text-white">
            File Size: {prettyBytes(file.size)}
          </span>
          {disableUpload && (
            <span className="mb-6 flex h-[20px]  justify-start overflow-hidden overflow-ellipsis text-center text-red-300">
              File size is bigger than 100 MB!
            </span>
          )}
        </>
      )}
      <motion.div
        whileHover={{ scale: 1.1 }}
        style={{
          opacity: disabled ? 0.2 : 1
        }}
      >
        <label htmlFor={id}>
          <span
            className={`rounded-lg border-[1px] border-white bg-transparent text-lg font-semibold text-white ${styles.uploadSRT}`}
          >
            {uploading ? `Uploading: ${uploadProgress}%` : text}
          </span>
        </label>
      </motion.div>

      <input
        className="z-[-1] h-1 opacity-0"
        type="file"
        disabled={disabled || uploading}
        id={id}
        accept={accept}
        onChange={(event) => onSelectFile(event, fileType)}
      />
    </div>
  );
}

export default S3FileUploadComponent;
