import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

export const uploadFileToS3 = async (
  file: File,
  url: string,
  key?: string,
  setProgress?: React.Dispatch<React.SetStateAction<unknown>>
) => {
  if (_isEmpty(url)) return null;
  const uninterceptedAxiosInstance = axios.create();
  const response = await uninterceptedAxiosInstance.put(url, file, {
    onUploadProgress(progressEvent) {
      setProgress && setProgress(() => (progressEvent?.progress * 100)?.toPrecision(4));
    }
  });
  return response;
};
